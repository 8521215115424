import {Timestamp} from "firebase/firestore";

export class AppUser {
  id = '';
  email = '';
  firstName = '';
  lastName = '';
  displayName = '';
  createdAt: Timestamp = Timestamp.now();
  updatedAt: Timestamp = Timestamp.now();
}
