import {Injectable} from '@angular/core';
import {AppUser} from '../types/user.interface';
import {UserRepositoryService} from "../repositories/user-repository.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(
    private repository: UserRepositoryService
  ) {
  }

  getById(id: string): Observable<any> {
    return this.repository.getById(id);
  }

  getAll(): Observable<AppUser[]> {
    return this.repository.getAll();
  }

  upsert(userData: AppUser) {
    this.repository.upsert(userData);
  }
  update(user: AppUser) {
    this.repository.upsert(user);
  }

  delete(id: string): Observable<void> {
    return this.repository.deleteDocument(id);
  }
}