import {Injectable} from '@angular/core';
import {AppUser} from '../types/user.interface';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { AuthCredential, EmailAuthProvider } from 'firebase/auth';
import {BehaviorSubject, Observable} from "rxjs";
import {UserService} from "./user.service";
import { GoogleAuthProvider } from '@angular/fire/auth';
import {Timestamp} from "firebase/firestore";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public appUser: AppUser | null = null;
  public initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private userService: UserService,
    private auth: AngularFireAuth,
    private router: Router,
  ) {
    this.auth.user.subscribe(user => {
      if (user?.uid) {
        if (this.appUser?.id !== user.uid) {
          this.initialized.next(false);
          this.initialize(user.uid).subscribe(status => {
            if (status == 1) {
              this.initialized.next(true);
            }
          });
        }
      } else {
        this.clearUser();
      }
    });
  }

  public initialize(uid: string): Observable<number> {
    let status = 0;
    return new Observable<number>(initializer => {
      try {
        initializer.next(status);
        this.userService.getById(uid).subscribe(result => {
          this.appUser = result.data();
          if (this.appUser) {
            status = 1;
            initializer.next(status);
          }
        });
      } catch (error) {
        console.log(error);
        initializer.error(error);
        initializer.complete();
      }
    });
  }

  private clearUser(): void {
    this.appUser = null;
  }

  signUp(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }
  signIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  signOut() {
    this.auth.signOut().then(() => {
      this.clearUser();
      this.router.navigate(['login']).then();
    });
  }

  googleLogin() {
    this.auth.signInWithPopup(new GoogleAuthProvider()).then(googleResponse => {
      const user = googleResponse.user;
      if(user?.uid) {
        this.userService.getById(user.uid).subscribe(existingUser => {
          if (!existingUser.exists) {
            console.log('User does not exist, creating new user.');
            const userData: AppUser = {
              id: user.uid,
              email: user.email ?? "",
              displayName: user.displayName ?? "",
              firstName: user.displayName?.split(' ')[0] ?? "",
              lastName: user.displayName?.split(' ')[1] ?? "",
              createdAt: Timestamp.now(),
              updatedAt: Timestamp.now(),
            }
            this.userService.upsert(userData);
          }
          this.router.navigate(['/dashboard']).then();
        });
      }

    }).catch(err => {
      // Login error
      console.log(err);
    });
  }

  verifyEmail() {
    return new Promise((resolve, reject) => {
      this.auth.user.subscribe({
        next: user => {
          if (user) {
            user.sendEmailVerification().then(() => {
              resolve(true);
            }).catch((error) => {
              resolve(error);
            });
          } else {
            resolve(false);
          }
        },
        error: error => {
          reject(error);
        }
      });
    });
  }
  checkEmailVerified(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.auth.user.subscribe({
        next: user => {
          if (user) {
            resolve(user.emailVerified);
          } else {
            resolve(false);
          }
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  async deleteAccount(email: string, password: string): Promise<boolean> {
    await this.reauthenticateUser(email, password);
    return await new Promise((resolve, reject) => {
      this.auth.currentUser.then(user => {
        if (user) {
          const uid = user.uid;
          user.delete().then(() => {
            this.userService.delete(uid);
            this.clearUser();
            this.router.navigate(['login']).then();
            resolve(true);
          }).catch((error) => {
            reject(error);
          });
        } else {
          reject(new Error('No user is currently signed in.'));
        }
      }).catch(error_1 => {
        reject(error_1);
      });
    });
  }

  forgotPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }
  verifyCode(code: string) {
    return this.auth.verifyPasswordResetCode(code);
  }
  resetPassword(code: string, password: string) {
    return this.auth.confirmPasswordReset(code, password);
  }

  reauthenticateUser(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth.currentUser.then(user => {
        if (user) {
          const credential: AuthCredential = EmailAuthProvider.credential(email, password);
          user.reauthenticateWithCredential(credential).then(() => {
            resolve();
          }).catch(error => {
            reject(error);
          });
        } else {
          reject(new Error('No user is currently signed in.'));
        }
      }).catch(error => {
        reject(error);
      });
    });
  }
}
